import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useMemo,
} from 'react';
import { Spin, Tabs } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';
import debounce from 'lodash/debounce';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import SearchResult, { CalendarSearchResult } from '../types/SearchResult';
import { PlaceSuggestion } from '../../components/AddressSearchInput';
import PaginationParams from '../../types/PaginationParams';
import PaginationMeta from '../../types/PaginationMeta';
import ContactPerson from '../../bookingOrders/types/ContactPerson';
import { DisplayedCourseInstance } from '../../courseInstance/types/CourseInstance';
import Course from '../../course/types/Course';
import CreateCourseInstanceModal from '../../courseInstance/components/CreateCourseInstanceModal';
import SearchAPI, { SearchParams } from '../SearchAPI';

import CalendarSearchResultModal from './CalendarSearchResultModal';
import SearchCalendar from './SearchCalendar';
import SearchResults from './SearchResults';
import SearchFilter from './SearchFilter';

type SearchProps = {
  contactPerson?: ContactPerson;
  onSubmit?: (displayedCourseInstance: DisplayedCourseInstance) => void;
  displayedCourseInstance?: DisplayedCourseInstance;
};

const Search: React.FC<SearchProps> = ({
  contactPerson,
  onSubmit,
  displayedCourseInstance,
}) => {
  const [tab, setTab] = useState<string>();
  const [dateString, setDateString] = useState<string>();
  const [calendarResults, setCalendarResults] =
    useState<CalendarSearchResult[]>();
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [loadingSearchResults, setLoadingSearchResults] =
    useState<boolean>(false);
  const [activeSearchResult, setActiveSearchResult] =
    useState<SearchResult | null>(null);
  const [placeSuggestion, setPlaceSuggestion] =
    useState<PlaceSuggestion | null>();
  const [searchedCourse, setSearchedCourse] = useState<Course>();
  const [params, setParams] = useState<SearchParams>();
  const [paginationParams, setPaginationParams] =
    useState<PaginationParams | null>({
      page: 1,
    });
  const [selectedDay, setSelectedDay] = useState<Date | null>();
  const [paginationMeta, setPaginationMeta] = useState<PaginationMeta | null>();
  const searchResultsRef = useRef<SearchResult[]>(searchResults);
  searchResultsRef.current = searchResults;
  const [searchSearchResultsGroup, setSearchSearchResultsGroup] =
    useState<Array<{ title: string; rows: SearchResult[] }>>();
  const [noInstructorModal, setNoInstructorModal] = useState<boolean>(false);
  const [showCalendarSearchResultModal, setShowCalendarSearchResultModal] =
    useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    setTab('calendar');
  }, []);

  const search = useCallback(
    async (
      _params: SearchParams,
      _paginationParams?: PaginationParams | null,
    ) => {
      setLoadingSearchResults(true);
      if (tab !== 'calendar') {
        try {
          const { data: res } = await SearchAPI.search(
            _params,
            _paginationParams,
          );
          setSearchResults(searchResultsRef.current.concat(res.data));
          searchResultsRef.current = searchResultsRef.current.concat(res.data);
          setPaginationMeta(res.meta);
          setPaginationParams({ page: res.meta.page + 1 });
        } finally {
          setLoadingSearchResults(false);
        }

        const capitalize = (s: string) => {
          return s.charAt(0).toUpperCase() + s.slice(1);
        };

        const days: {
          [key: string]: SearchResult[];
        } = searchResultsRef.current.reduce(
          (acc: { [key: string]: SearchResult[] }, searchResult) => {
            const days = capitalize(
              dayjs(searchResult.start).format('dddd DD MMMM YYYY'),
            );
            return {
              ...acc,
              [days]: (acc[days] ?? []).concat(searchResult),
            };
          },
          {},
        );

        Object.keys(days).forEach(
          (day) =>
            (days[day] = days[day].sort((a, b) => a.distance - b.distance)),
        );

        const groups = Object.keys(days).map((day) => ({
          title: day,
          rows: days[day],
        }));

        const sortedGroups = groups.sort(
          (a, b) => +dayjs(a.rows[0].start) - +dayjs(b.rows[0].start),
        );

        setSearchSearchResultsGroup(sortedGroups);
      } else {
        try {
          const { data } = await SearchAPI.calendarSearch({
            ..._params,
            month: dayjs(dateString).format('YYYY-MM'),
          });
          setCalendarResults(data);
        } finally {
          setLoadingSearchResults(false);
        }
      }
    },
    [dateString, tab],
  );

  const onFilterSearch = useCallback(
    (
      _params: SearchParams,
      _suggestion?: PlaceSuggestion | null,
      _course?: Course,
    ) => {
      setPaginationParams({ page: 1 });
      setPaginationMeta(null);
      setSearchResults([]);
      setPlaceSuggestion(_suggestion);
      setParams(_params);
      search(_params, { page: 1 });
      setSearchedCourse(_course);
    },
    [search],
  );

  useEffect(() => {
    if (dateString && params) {
      search(params, { page: 1 });
    }
  }, [dateString, params, search]);

  useEffect(() => {
    if (selectedDay != null) {
      setShowCalendarSearchResultModal(true);
    } else {
      setShowCalendarSearchResultModal(false);
    }
  }, [selectedDay]);

  /*
   * Debounce the call to search to prevent the infinite scroll component
   * from calling it multiple times before the state has finished updating.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce(
      (_params: SearchParams, _paginationParams?: PaginationParams | null) =>
        search(_params, _paginationParams),
      10,
    ),
    [],
  );

  const openCourseInstanceModal = useCallback((searchResult: SearchResult) => {
    setActiveSearchResult(searchResult);
  }, []);

  const closeCourseInstanceModal = useCallback(() => {
    setActiveSearchResult(null);
  }, []);

  const closeNoInstructorModal = useCallback(() => {
    setNoInstructorModal(false);
  }, []);

  const onSuccessfulSubmit = useCallback(
    (displayedCourseInstance: DisplayedCourseInstance) => {
      if (onSubmit) {
        onSubmit(displayedCourseInstance);
      }
      setCalendarResults(undefined);
      setSearchResults([]);
      closeNoInstructorModal();
      closeCourseInstanceModal();
    },
    [closeCourseInstanceModal, closeNoInstructorModal, onSubmit],
  );

  const tabItems = useMemo(
    () => [
      {
        key: 'calendar',
        label: t('components.Search.calendar'),
        children: calendarResults ? (
          <SearchCalendar
            setDateString={setDateString}
            fetchedEvents={calendarResults}
            onClickDay={setSelectedDay}
            loadingSearchResults={loadingSearchResults}
          />
        ) : (
          <div className="flex flex-col">
            {loadingSearchResults && (
              <Spin
                size="large"
                className="mt-4 self-center justify-self-center"
              />
            )}
          </div>
        ),
      },
      {
        key: 'list',
        label: t('common.list'),
        children: (
          <InfiniteScroll
            loadMore={() => {
              setLoadingSearchResults(true);
              if (!loadingSearchResults) {
                return debounceSearch(params!, {
                  ...paginationParams,
                  page: paginationParams?.page,
                });
              }
            }}
            hasMore={
              !loadingSearchResults &&
              (paginationMeta?.page ?? 1) < (paginationMeta?.lastPage ?? 1)
            }>
            <SearchResults
              results={searchSearchResultsGroup}
              loading={loadingSearchResults}
              onBook={openCourseInstanceModal}
              onBookWithoutInstructor={setNoInstructorModal}
            />
          </InfiniteScroll>
        ),
      },
    ],
    [
      calendarResults,
      debounceSearch,
      loadingSearchResults,
      openCourseInstanceModal,
      paginationMeta?.lastPage,
      paginationMeta?.page,
      paginationParams,
      params,
      searchSearchResultsGroup,
      t,
    ],
  );

  const selectedDate = useMemo(() => dayjs(selectedDay), [selectedDay]);

  return (
    <>
      <SearchFilter
        onSearch={onFilterSearch}
        disableListSearch={tab === 'calendar'}
        loading={loadingSearchResults}
        initialFilterValues={{
          courseId: displayedCourseInstance?.course.id,
          location:
            displayedCourseInstance?.address &&
            displayedCourseInstance?.placeId &&
            displayedCourseInstance?.lat &&
            displayedCourseInstance?.lng
              ? {
                  address: displayedCourseInstance?.address,
                  placeId: displayedCourseInstance?.placeId,
                  geolocation: {
                    lat: displayedCourseInstance?.lat,
                    lng: displayedCourseInstance?.lng,
                  },
                }
              : undefined,
        }}
      />
      <Tabs
        defaultActiveKey="calendar"
        size="large"
        onTabClick={(info) => setTab(info)}
        onChange={(info) =>
          info === 'list' &&
          setParams({ ...params!, from: dayjs().toISOString() })
        }
        items={tabItems}
      />
      {noInstructorModal && (
        <CreateCourseInstanceModal
          course={searchedCourse}
          placeSuggestion={placeSuggestion}
          displayedCourseInstance={displayedCourseInstance}
          onSuccessfulSubmit={onSuccessfulSubmit}
          onCancel={() => {
            closeNoInstructorModal();
            setShowCalendarSearchResultModal(true);
          }}
          visible={!!noInstructorModal}
          searchedDate={dayjs(params?.from)}
          searchedCourseId={params?.courseId}
          contactPerson={contactPerson}
        />
      )}
      {activeSearchResult && (
        <CreateCourseInstanceModal
          instructorId={activeSearchResult.user.id}
          start={activeSearchResult.start}
          end={activeSearchResult.end}
          course={activeSearchResult.course}
          courseInstanceLimit={activeSearchResult.courseInstanceLimitOnDay}
          placeSuggestion={placeSuggestion}
          displayedCourseInstance={displayedCourseInstance}
          distance={activeSearchResult.distance}
          onSuccessfulSubmit={(courseInstance) =>
            onSuccessfulSubmit(courseInstance)
          }
          onCancel={() => {
            setShowCalendarSearchResultModal(true);
            closeCourseInstanceModal();
          }}
          contactPerson={contactPerson}
          mileage={activeSearchResult.mileage}
          visible
        />
      )}

      {showCalendarSearchResultModal && selectedDay && params && (
        <CalendarSearchResultModal
          setParams={setParams}
          searchParams={params}
          date={selectedDate}
          onCancel={() => setSelectedDay(null)}
          onBook={openCourseInstanceModal}
          onBookWithoutInstructor={setNoInstructorModal}
          setShowCalendarSearchResultModal={setShowCalendarSearchResultModal}
        />
      )}
    </>
  );
};

export default Search;
