import ApiResponse from '../../types/ApiResponse';
import ContactPerson from '../types/ContactPerson';
import BookingOrder from '../types/BookingOrder';
import ClientCompany from '../types/ClientCompany';
import InvoiceRecipient from '../types/InvoiceRecipient';
import PaginationMeta from '../../types/PaginationMeta';
import PaginationParams from '../../types/PaginationParams';
import SubsidiaryCompany from '../../subsidiaryCompany/types/SubsidiaryCompany';
import User from '../../user/types/User';
import BookingOrderComment from '../types/BookingOrderComment';
import Course from '../../course/types/Course';
import DateCount from '../../insights/types/DateCount';
import client from '../../api/client';

export type CreateBookingOrderParams = {
  clientCompanyId: number;
  contactPersonId?: number;
  invoiceRecipientId?: number;
  subsidiaryCompanyId: number;
  responsibleBookerId?: number;
  createCourseInstanceParams?: BookingOrderCreateCourseInstanceParams[];
  invoiceReference?: string;
};

export type UpdateBookingOrderParams = {
  clientCompanyId: number;
  contactPersonId?: number;
  invoiceRecipientId?: number;
  subsidiaryCompanyId: number;
  responsibleBookerId?: number;
  invoiceReference?: string;
};

export type BookingOrderCreateCourseInstanceParams = {
  courseInstanceId?: number;
  courseId: number;
  startsAt?: string;
  endsAt?: string;
  contactPersonId?: number;
  instructorId?: number | null;
  canceled?: boolean;
  confirmed?: boolean;
  price: number;
  currencyId: number;
  address?: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  comment?: string;
  equipment?: string;
  mileageQuantity?: number;
  mileagePrice?: number;
};

export type BookingOrderApiFilter = {
  clientCompanyIds?: ClientCompany['id'][];
  invoiceRecipientIds?: (InvoiceRecipient['id'] | null)[];
  contactPersonids?: ContactPerson['id'][];
  subsidiaryCompanyIds?: SubsidiaryCompany['id'][];
  responsibleBookerIds?: User['id'][];
  isInvoiced?: BookingOrder['isInvoiced'];
  from?: string;
  to?: string;
  statuses?: BookingOrder['status'][];
  hasVismaId?: boolean;
  hasInvoiceRecipient?: boolean;
  courseInstancePriceGreaterThan?: number;
  courseIds?: Course['id'][];
  instructorIds?: User['id'][];
};

export type IncompleteBookingOrderApiFilter = {
  subsidiaryCompanyIds?: BookingOrderApiFilter['subsidiaryCompanyIds'];
  responsibleBookerIds?: User['id'][];
  isInvoiced?: BookingOrder['isInvoiced'];
  from?: BookingOrderApiFilter['from'];
  to?: BookingOrderApiFilter['to'];
  statuses?: BookingOrder['status'][];
};

type SetIsInvoicedParams = {
  isInvoiced: boolean;
};

const getBookingOrders = (
  filter?: BookingOrderApiFilter,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<BookingOrder[], PaginationMeta>>('/booking-orders', {
    params: {
      ...filter,
      ...paginationParams,
    },
  });

const getIncompleteBookingOrders = (
  IncompleteBookingOrderFilter?: IncompleteBookingOrderApiFilter,
  paginationParams?: PaginationParams | null,
) =>
  client.get<ApiResponse<BookingOrder[], PaginationMeta>>(
    '/booking-orders/incomplete',
    {
      params: {
        ...IncompleteBookingOrderFilter,
        ...paginationParams,
      },
    },
  );

const getBookingOrder = (id: BookingOrder['id']) =>
  client.get<BookingOrder>(`/booking-orders/${id}`);

const getLimeBookingOrder = (limeId: number, subsidiaryCompanyId: number) =>
  client.get<BookingOrder>(
    `/booking-orders/subsidiary-company/${subsidiaryCompanyId}/lime-booking-order/${limeId}`,
  );

const createBookingOrder = (params: CreateBookingOrderParams) =>
  client.post<BookingOrder>('/booking-orders', params);

const updateBookingOrder = (
  id: BookingOrder['id'],
  params: UpdateBookingOrderParams,
) => client.patch<BookingOrder>(`/booking-orders/${id}`, params);

const deleteBookingOrder = (id: BookingOrder['id']) =>
  client.delete<BookingOrder>(`/booking-orders/${id}`);

const createBookingOrderComment = (id: BookingOrder['id'], text: string) =>
  client.post<BookingOrderComment>(`/booking-orders/${id}/comments`, { text });

const updateBookingOrderComment = (
  id: BookingOrderComment['id'],
  text: string,
) =>
  client.patch<BookingOrderComment>(`/booking-orders/comments/${id}`, { text });

const deleteBookingOrderComment = (id: BookingOrderComment['id']) =>
  client.delete<BookingOrderComment>(`/booking-orders/comments/${id}`);

const createBookingOrderInVisma = (id: BookingOrder['id']) =>
  client.post<BookingOrder>(`/booking-orders/${id}/visma-sales-order`);

const getBookingOrdersCreated = (
  fromDate: string,
  toDate: string,
  subsidiaryCompanyIds?: number[] | null,
) =>
  client.get<DateCount[]>(`/booking-orders/created`, {
    params: {
      fromDate: fromDate,
      toDate: toDate,
      subsidiaryCompanyIds: subsidiaryCompanyIds,
    },
  });

const setIsInvoiced = (id: BookingOrder['id'], params: SetIsInvoicedParams) =>
  client.patch<BookingOrder>(`/booking-orders/${id}/invoice`, params);

export default {
  getBookingOrders,
  getBookingOrder,
  getIncompleteBookingOrders,
  getLimeBookingOrder,
  createBookingOrder,
  updateBookingOrder,
  deleteBookingOrder,
  createBookingOrderComment,
  updateBookingOrderComment,
  deleteBookingOrderComment,
  createBookingOrderInVisma,
  getBookingOrdersCreated,
  setIsInvoiced,
};
