import { CardDataUser, MinimalUser } from '../../user/types/User';
import Course, { CourseCardData } from '../../course/types/Course';
import ContactPerson from '../../bookingOrders/types/ContactPerson';
import Currency from '../../types/Currency';

export default interface CourseInstance {
  id: number;
  course: Course;
  orderId: number;
  createdBy?: MinimalUser;
  startsAt?: string;
  endsAt?: string;
  contactPerson?: ContactPerson;
  instructor?: MinimalUser | null;
  status: CourseInstanceStatus;
  price: number;
  currency: Currency;
  address?: string;
  placeId?: string;
  lat?: number;
  lng?: number;
  comment?: string;
  equipment?: string;
  mileageQuantity?: number;
  mileagePrice?: number;
  elsaTrainingSessionId?: string;
  elsaTrainingSessionUrl?: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

// Course instance with optional booking order field
export interface DisplayedCourseInstance {
  id?: CourseInstance['id'];
  course: CourseInstance['course'];
  orderId?: CourseInstance['orderId'];
  createdBy?: CourseInstance['createdBy'];
  startsAt?: CourseInstance['startsAt'];
  endsAt?: CourseInstance['endsAt'];
  contactPerson?: CourseInstance['contactPerson'];
  instructor?: CourseInstance['instructor'];
  status?: CourseInstance['status'];
  price: CourseInstance['price'];
  currency: CourseInstance['currency'];
  address?: CourseInstance['address'];
  placeId?: CourseInstance['placeId'];
  lat?: CourseInstance['lat'];
  lng?: CourseInstance['lng'];
  comment?: CourseInstance['comment'];
  mileageQuantity?: CourseInstance['mileageQuantity'];
  mileagePrice?: CourseInstance['mileagePrice'];
  equipment?: CourseInstance['equipment'];
  elsaTrainingSessionId?: CourseInstance['elsaTrainingSessionId'];
  elsaTrainingSessionUrl?: CourseInstance['elsaTrainingSessionUrl'];
  createdAt?: CourseInstance['createdAt'];
  updatedAt?: CourseInstance['updatedAt'];
  deletedAt?: CourseInstance['deletedAt'];
  confirmed?: boolean;
  canceled?: boolean;
}

export interface InstructorCourseInstance {
  id?: CourseInstance['id'];
  course: CourseInstance['course'];
  createdBy?: CourseInstance['createdBy'];
  startsAt?: CourseInstance['startsAt'];
  endsAt?: CourseInstance['endsAt'];
  contactPerson?: CourseInstance['contactPerson'];
  instructor?: CourseInstance['instructor'];
  status?: CourseInstance['status'];
  address?: CourseInstance['address'];
  placeId?: CourseInstance['placeId'];
  lat?: CourseInstance['lat'];
  lng?: CourseInstance['lng'];
  comment?: CourseInstance['comment'];
  equipment?: CourseInstance['equipment'];
  createdAt?: CourseInstance['createdAt'];
  updatedAt?: CourseInstance['updatedAt'];
  deletedAt?: CourseInstance['deletedAt'];
  subsidiaryCompanyName?: string;
  clientName?: string;
}

export interface CalendarCourseInstanceInfo {
  nBookings: number;
  date: string;
}

export interface CourseInstanceCardData {
  id: CourseInstance['id'];
  orderId?: CourseInstance['orderId'];
  course?: CourseCardData;
  subsidiaryCompanyName?: string;
  clientName?: string;
  startsAt?: CourseInstance['startsAt'];
  endsAt?: CourseInstance['endsAt'];
  contactPerson?: CourseInstance['contactPerson'];
  instructor?: CardDataUser | null;
  status: CourseInstance['status'];
  address?: CourseInstance['address'];
  createdByName?: string;
}

export enum CourseInstanceStatus {
  Canceled = 'canceled',
  Preliminary = 'preliminary',
  Confirmed = 'confirmed',
  Ordered = 'ordered',
}

export interface CourseInstanceHeatMapData {
  id: number;
  lat: number;
  lng: number;
}

export enum CourseInstanceAttendeeStatus {
  Passed = 'Passed',
  Failed = 'Failed',
  DidNotParticipate = 'DidNotParticipate',
}

export type CourseInstanceAttendee = {
  id: number;
  name: string;
  email?: string;
  status?: CourseInstanceAttendeeStatus;
  comment?: string;
  prerequisiteDigitalCourseCompletedPercentage?: number;
};

export interface CourseInstanceAttendeeComplete extends CourseInstanceAttendee {
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}
