import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  Form,
  Input,
  TimePicker,
  DatePicker,
  Alert,
  Select,
  Space,
  InputNumber,
} from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import ContactPerson from '../../bookingOrders/types/ContactPerson';
import * as API from '../../api';
import ContactPersonAPI from '../../bookingOrders/api/ContactPersonAPI';
import UserAPI from '../../user/UserAPI';
import CourseInstanceAPI from '../CourseInstanceAPI';
import * as DateUtils from '../../utils/date';
import Course from '../../course/types/Course';
import User from '../../user/types/User';
import { useUser } from '../../user/userContext';
import { formatDistance } from '../../utils/distance';
import {
  CourseInstanceCardData,
  CourseInstanceStatus,
  DisplayedCourseInstance,
} from '../types/CourseInstance';
import Mileage from '../../search/types/Mileage';
import Currency from '../../types/Currency';
import CourseAPI from '../../course/CourseAPI';
import AddressSearchInput, {
  PlaceSuggestion,
} from '../../components/AddressSearchInput';
import CourseOption from '../../components/CourseOption';
import FieldSelect from '../../components/FieldSelect';
import CreateContactPersonModal from '../../bookingOrders/components/CreateContactPersonModal';
import { getFullContactName } from '../../bookingOrders/utils/contactPerson';
import InstructorCourseInstanceAlert from '../../components/InstructorCourseInstanceAlert';

type CreateCourseInstanceModalProps = {
  instructorId?: number;
  course?: Course;
  start?: string;
  end?: string;
  placeSuggestion?: PlaceSuggestion | null;
  displayedCourseInstance?: DisplayedCourseInstance | null;
  distance?: number;
  onSuccessfulSubmit: (
    displayedCourseInstance: DisplayedCourseInstance,
  ) => void;
  onCancel: () => void;
  visible?: boolean;
  searchedDate?: dayjs.Dayjs;
  searchedCourseId?: number;
  courseInstanceLimit?: number;
  contactPerson?: ContactPerson;
  mileage?: Mileage;
};

const CreateCourseInstanceModal: React.FC<CreateCourseInstanceModalProps> = ({
  instructorId,
  course,
  start,
  end,
  placeSuggestion,
  displayedCourseInstance,
  distance,
  onSuccessfulSubmit,
  onCancel,
  visible,
  searchedDate,
  searchedCourseId,
  courseInstanceLimit,
  contactPerson,
  mileage,
}) => {
  const [form] = Form.useForm();
  const [validCourseInstances, setValidCourseInstances] = useState<
    CourseInstanceCardData[]
  >([]);
  const [loadingCourseInstances, setLoadingCourseInstances] =
    useState<boolean>(false);
  const [currentUser] = useUser();
  const [courses, setCourses] = useState<Course[]>([]);
  const [instructor, setInstructor] = useState<User>();
  const [loadingInstructor, setLoadingInstructor] = useState<boolean>();
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [contactPersons, setContactPersons] = useState<ContactPerson[]>();
  const [loadingContactPersons, setLoadingContactPersons] =
    useState<boolean>(false);
  const [showCreateContactPersonModal, setShowCreateContactPersonModal] =
    useState<boolean>(false);
  const [requireDateTime, setRequireDateTime] = useState<boolean>(false);
  const [defaultCurrency, setDefaultCurrency] = useState<Currency>();
  const [loadingDefaultCurrency, setLoadingDefaultCurrency] = useState(false);

  const { t } = useTranslation();

  const fetchInstructor = useCallback(async () => {
    if (instructorId) {
      setLoadingInstructor(true);
      try {
        const { data } = await UserAPI.getUser(instructorId);
        setInstructor(data);
      } finally {
        setLoadingInstructor(false);
      }
    }
  }, [instructorId]);

  const fetchDefaultCurrency = useCallback(async () => {
    setLoadingDefaultCurrency(true);
    try {
      const { data } = await API.getCurrencies(
        currentUser!.market.defaultCurrency.code,
      );
      setDefaultCurrency(data[0]);
    } finally {
      setLoadingDefaultCurrency(false);
    }
  }, [currentUser]);

  const fetchAllCourses = useCallback(async () => {
    setLoadingCourses(true);
    try {
      const { data } = await CourseAPI.getCourses();
      setCourses(data);
    } finally {
      setLoadingCourses(false);
    }
  }, []);

  const fetchCourseInstances = useCallback(async () => {
    if (instructorId) {
      setLoadingCourseInstances(true);
      try {
        const { data } = await CourseInstanceAPI.getCourseInstances({
          instructorIds: [instructorId],
          startsAt: dayjs(start).startOf('day').toISOString(),
          endsAt: dayjs(end).endOf('day').toISOString(),
          status: [
            CourseInstanceStatus.Confirmed,
            CourseInstanceStatus.Ordered,
            CourseInstanceStatus.Preliminary,
          ],
        });
        setValidCourseInstances(data.data);
      } finally {
        setLoadingCourseInstances(false);
      }
    }
  }, [end, instructorId, start]);

  const fetchContactPersons = useCallback(async () => {
    setLoadingContactPersons(true);
    try {
      const { data } = await ContactPersonAPI.getContactPersons();
      setContactPersons(data);
    } finally {
      setLoadingContactPersons(false);
    }
  }, []);

  useEffect(() => {
    fetchInstructor();
  }, [fetchInstructor]);

  useEffect(() => {
    fetchDefaultCurrency();
  }, [fetchDefaultCurrency]);

  useEffect(() => {
    fetchContactPersons();
  }, [fetchContactPersons]);

  useEffect(() => {
    if (instructorId && start && end) {
      fetchCourseInstances();
    } else {
      fetchAllCourses();
    }
    setRequireDateTime(
      !!form.getFieldValue('date') || !!form.getFieldValue('time'),
    );
  }, [end, fetchAllCourses, fetchCourseInstances, form, instructorId, start]);

  const onCreateContactPerson = (contactPerson: ContactPerson) => {
    setShowCreateContactPersonModal(false);
    fetchContactPersons();
    form.setFieldsValue({ contactPersonId: contactPerson.id });
  };

  const courseOptions = useCallback(() => {
    if (!instructor) {
      return (
        <>
          {courses.map((c) => (
            <Select.Option value={c.id} key={c.id}>
              <CourseOption user={currentUser} course={c} />
            </Select.Option>
          ))}
        </>
      );
    } else {
      return (
        <>
          {course == null ? (
            instructor.courses.map((c) => (
              <Select.Option value={c.id} key={c.id}>
                <CourseOption user={currentUser} course={c} />
              </Select.Option>
            ))
          ) : (
            <Select.Option value={course?.id}>{course?.name}</Select.Option>
          )}
        </>
      );
    }
  }, [course, courses, currentUser, instructor]);

  type FormValues = {
    id?: number;
    courseId: number;
    clientAddress?: PlaceSuggestion;
    clientCorporateId?: string;
    invoiceAddress?: string;
    contactPersonId?: number;
    subsidiaryCompanyId: number;
    price?: number;
    equipment: string;
    time: [dayjs.Dayjs, dayjs.Dayjs];
    timeSlotId: number;
    comment: string;
    address: PlaceSuggestion;
    exactAddress?: string;
    date: dayjs.Dayjs;
    mileageQuantity?: number;
    mileagePrice?: number;
  };

  const onFinish = (values: FormValues) => {
    if (defaultCurrency) {
      onSuccessfulSubmit?.({
        id: displayedCourseInstance?.id,
        course:
          courses.find((course) => course.id === values.courseId)! ?? course,
        startsAt: requireDateTime
          ? DateUtils.mergeDateTime(
              values.date,
              values.time[0],
              true,
            ).toISOString()
          : undefined,
        endsAt: requireDateTime
          ? DateUtils.mergeDateTime(
              values.date,
              values.time[1],
              true,
            ).toISOString()
          : undefined,
        contactPerson: contactPersons?.find(
          (contactPerson) => contactPerson.id === values.contactPersonId,
        ),
        instructor: instructor ?? null,
        price: values.price ?? 0,
        currency: defaultCurrency,
        address: placeSuggestion?.address ?? values.address.address,
        placeId: placeSuggestion?.placeId ?? values.address.placeId,
        lat: placeSuggestion?.geolocation.lat ?? values.address.geolocation.lat,
        lng: placeSuggestion?.geolocation.lng ?? values.address.geolocation.lng,
        comment: values.comment,
        equipment: values.equipment,
        mileageQuantity: values.mileageQuantity ?? 0,
        mileagePrice: values.mileagePrice ?? 0,
      });
    }
  };

  return (
    <>
      {!loadingInstructor && !loadingDefaultCurrency && (
        <>
          <Modal
            centered
            title={t('components.CreateCourseInstanceModal.bookCourse')}
            open={visible}
            onCancel={onCancel}
            onOk={form.submit}
            footer={[
              <Button key="back" onClick={onCancel}>
                {t('common.close')}
              </Button>,
              <Button
                key="submit"
                type="primary"
                form="createCourseInstanceForm"
                htmlType="submit">
                {t('components.CreateCourseInstanceModal.book')}
              </Button>,
            ]}
            maskClosable={false}>
            {instructor?.maxTravelDistance && (
              <>
                {mileage ? (
                  <div className="mb-2">
                    <Alert
                      message={t(
                        'components.CreateCourseInstanceModal.drivingDistance',
                        {
                          distance: formatDistance(
                            mileage.distanceInMeters!,
                            t,
                          ),
                          instructorMax: instructor?.maxTravelDistance,
                        },
                      )}
                      type="warning"
                    />
                  </div>
                ) : (
                  <div className="mb-2">
                    {distance !== undefined && distance !== null && (
                      <Alert
                        message={t(
                          'components.CreateCourseInstanceModal.directDistance',
                          {
                            distance: formatDistance(distance!, t),
                            instructorMax: instructor?.maxTravelDistance,
                          },
                        )}
                        type="warning"
                      />
                    )}
                  </div>
                )}
              </>
            )}

            {validCourseInstances.length > 0 &&
              start &&
              end &&
              courseInstanceLimit && (
                <div className="mb-6">
                  {validCourseInstances.length >= courseInstanceLimit && (
                    <Alert
                      className="mb-2"
                      message={t(
                        'components.CreateCourseInstanceModal.instructorTooManyInstances',
                        {
                          currentAmount: validCourseInstances.length,
                          maxAmount: courseInstanceLimit,
                        },
                      )}
                      type="warning"
                      showIcon
                    />
                  )}
                  {instructor && (
                    <InstructorCourseInstanceAlert
                      instructorName={instructor.name}
                      loadingCourseInstances={loadingCourseInstances}
                      courseInstanceCardData={validCourseInstances}
                    />
                  )}
                </div>
              )}
            <Form
              form={form}
              id="createCourseInstanceForm"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={(values) => onFinish(values as FormValues)}>
              <Form.Item
                label={t('common.course')}
                name="courseId"
                rules={[
                  {
                    required: true,
                    message: t(
                      'components.CreateCourseInstanceModal.selectCourse',
                    ),
                  },
                ]}
                initialValue={course?.id ?? searchedCourseId}>
                <Select
                  loading={loadingCourses}
                  placeholder={t('common.course')}
                  className="w-full"
                  allowClear
                  disabled={
                    course != undefined || searchedCourseId != undefined
                  }>
                  {courseOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('common.instructor')}
                initialValue={instructor?.id}>
                <Input
                  disabled
                  value={
                    instructor?.name ??
                    t('components.CreateCourseInstanceModal.noInstructor')
                  }
                />
              </Form.Item>
              <FieldSelect
                placeholderText={t(
                  'components.CreateCourseInstanceModal.selectContactPerson',
                )}
                createButtonText={t(
                  'components.CreateCourseInstanceModal.createNewContactPerson',
                )}
                loading={loadingContactPersons}
                elements={contactPersons?.map((contactPerson) => ({
                  primaryText: getFullContactName(contactPerson),
                  secondaryText: contactPerson.email,
                  id: contactPerson.id,
                }))}
                initialValue={contactPerson?.id}
                formLabel={t(
                  'components.CreateCourseInstanceModal.contactPerson',
                )}
                formName="contactPersonId"
                className="mb-6"
                onCreate={() => setShowCreateContactPersonModal(true)}
              />
              <Form.Item
                label={t('common.address')}
                name="address"
                rules={[
                  placeSuggestion?.address
                    ? { required: false }
                    : {
                        required: true,
                        message: t(
                          'components.CreateCourseInstanceModal.enterAddress',
                        ),
                      },
                ]}>
                <AddressSearchInput
                  placeholder={placeSuggestion?.address ?? t('common.address')}
                  disabled={!!placeSuggestion?.address}
                />
              </Form.Item>
              <Form.Item
                label={t('common.date')}
                name="date"
                initialValue={start ? dayjs(start) : searchedDate}
                rules={[
                  {
                    required: requireDateTime,
                    message: t(
                      'components.CreateCourseInstanceModal.selectDate',
                    ),
                  },
                ]}>
                <DatePicker
                  disabled={!!instructor}
                  onChange={(value) =>
                    setRequireDateTime(!!value || !!form.getFieldValue('time'))
                  }
                />
              </Form.Item>
              <Form.Item
                label={t('common.time')}
                name="time"
                initialValue={
                  !!instructorId ? [dayjs(start), dayjs(end)] : null
                }
                rules={[
                  {
                    required: requireDateTime,
                    message: t(
                      'components.CreateCourseInstanceModal.selectTime',
                    ),
                  },
                ]}>
                <TimePicker.RangePicker
                  changeOnBlur={true}
                  minuteStep={5}
                  format="HH:mm"
                  onChange={(values) =>
                    setRequireDateTime(
                      (values?.[0] !== undefined &&
                        values?.[1] !== undefined) ||
                        !!form.getFieldValue('date'),
                    )
                  }
                />
              </Form.Item>
              <Form.Item
                label={t('components.CreateCourseInstanceModal.comment')}
                name="comment"
                initialValue={displayedCourseInstance?.comment}>
                <Input.TextArea
                  placeholder={t(
                    'components.CreateCourseInstanceModal.commentPlaceholder',
                  )}
                />
              </Form.Item>

              <Form.Item
                label={t('components.CreateCourseInstanceModal.equipment')}
                name="equipment"
                initialValue={displayedCourseInstance?.equipment}>
                <Input
                  placeholder={t(
                    'components.CreateCourseInstanceModal.equipment',
                  )}
                />
              </Form.Item>
              <Form.Item label={t('common.price')}>
                <Space>
                  <Form.Item
                    noStyle
                    name="price"
                    initialValue={
                      displayedCourseInstance?.price ?? course?.defaultPrice
                    }>
                    <InputNumber placeholder={t('common.price')} min={0} />
                  </Form.Item>
                  {defaultCurrency?.code}
                </Space>
              </Form.Item>
              <h3 className="border-solid border-gray-200 border-0 border-b-2 pb-1 mb-2">
                {t('common.mileage')}
              </h3>
              <Form.Item
                label={t(
                  'components.CreateCourseInstanceModal.mileageQuantity',
                )}
                name="mileageQuantity"
                initialValue={displayedCourseInstance?.mileageQuantity ?? 0}>
                <InputNumber
                  placeholder={t(
                    'components.CreateCourseInstanceModal.mileageQuantity',
                  )}
                  min={0}
                />
              </Form.Item>
              <Form.Item
                label={t('components.CreateCourseInstanceModal.mileagePrice')}
                name="mileagePrice"
                initialValue={displayedCourseInstance?.mileagePrice ?? 0}>
                <InputNumber
                  placeholder={t(
                    'components.CreateCourseInstanceModal.mileagePrice',
                  )}
                  min={0}
                  max={99}
                />
              </Form.Item>
            </Form>
          </Modal>
          {showCreateContactPersonModal && (
            <CreateContactPersonModal
              visible
              onCancel={() => setShowCreateContactPersonModal(false)}
              onCreate={(contactPerson) => onCreateContactPerson(contactPerson)}
            />
          )}
        </>
      )}
    </>
  );
};

export default CreateCourseInstanceModal;
